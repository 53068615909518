import React, { useState } from "react";

import Header from "../header/Header";
import Footer from "../footer/Footer";
import HomeScreen from "../homeScreen/HomeScreen";
import Intro from "../introScreen/Intro";
import Background from "../../images/background2.jpg";

import "./Layout.css";

function Layout() {
  const [introScreenFlag, showIntroScreenMethod] = useState(true);
  return (
    <div
      className="background"
      style={{
        backgroundImage: `url(${Background})`,
      }}
    >
      <Intro hideIntro={showIntroScreenMethod} showIntro={introScreenFlag} />
      <Header showAbout={showIntroScreenMethod} />
      <HomeScreen />
      <Footer />
    </div>
  );
}

export default Layout;
