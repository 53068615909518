import React, { useState, useEffect } from "react";
import moment from "moment";
import countdown from "countdown";

import "./HomeScreen.css";

function HomeScreen() {
  const [countdownObj, changeCountdownObj] = useState(
    countdown(moment("2024-07-07 14:55:00"))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      changeCountdownObj(countdown(moment("2024-07-07 14:55:00")));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="centerContent">
      <div className="timerContainer">
        <div className="timerSection">
          <span className="timerCount">{countdownObj.years}</span>
          <span className="border" />
          <span className="timerLabel borderBottomLeft">YEARS</span>
        </div>
        <div className="timerSection">
          <span className="timerCount">{countdownObj.months}</span>
          <span className="border" />
          <span className="timerLabel">MONTHS</span>
        </div>
        <div className="timerSection">
          <span className="timerCount">{countdownObj.days}</span>
          <span className="border" />
          <span className="timerLabel">DAYS</span>
        </div>
        <div className="timerSection">
          <span className="timerCount">{countdownObj.hours}</span>
          <span className="border" />
          <span className="timerLabel">HOURS</span>
        </div>
        <div className="timerSection">
          <span className="timerCount">{countdownObj.minutes}</span>
          <span className="border" />
          <span className="timerLabel">MINUTES</span>
        </div>
        <div className="timerSection">
          <span className="timerCount">{countdownObj.seconds}</span>
          <span className="timerLabel borderBottomRight">SECONDS</span>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
