import React from "react";
import Logo from "../../images/scrLogo.png";

import "./Header.css";

function Header(props) {
  return (
    <div className="headerContainer">
      <span className="flexContainer">
        <img alt="Harry" className="logoIcon" src={Logo} />
        <span className="titleText">HARRY</span>
      </span>
      <span className="flexContainer">
        <span className="menuText" onClick={() => props.showAbout(true)}>
          About
        </span>
      </span>
    </div>
  );
}

export default Header;
