import React from "react";
import "./Footer.css";

import Heart from "../../images/heart.png";

function Footer() {
  return (
    <div className="footerContainer">
      Made with <img alt="heart" className="heartIcon" src={Heart} /> by
      Doraemon.
    </div>
  );
}

export default Footer;
