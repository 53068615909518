import React from "react";
import Background from "../../images/introScreen.jpg";
import "./Intro.css";

function Intro(props) {
  return (
    <div
      className="introContainer"
      style={{
        opacity: props.showIntro ? 1 : 0,
        zIndex: props.showIntro ? 2 : 0,
        backgroundImage: `url(${Background})`,
      }}
    >
      <p className="introText">
        Harry, this is for you. Hope this website brings even one small smile to
        your face. It'll mean alot, if I succeed. This is just the start, more
        and more amazing things are on it's way. You have always been caring,
        and supportive; which is one of the best thing about you. Always be like
        this, and keep on smiling, and laughing.
        <br />
        Now coming to the website, this is to celebrate your birthday, by
        starting with the countdown. Something is hidden here, but also in front
        of your eyes; I hope you find it.
        <br />
        You know, I planned to gift you this website 6 months before your
        birthday, and calculated Feb 7 as 6 months, silly me. Work in progress,
        so please bear with it.
        <span>Harry, always be Harry.</span>
        <span className="text-600">-Akshu</span>
      </p>
      <div className="introCloseBtn" onClick={() => props.hideIntro(false)}>
        Let's Celebrate
      </div>
    </div>
  );
}

export default Intro;
